import axiosInstance from "../config/AxiosInstance";
import fetchInstance from "../config/FetchInstance";

const HttpService = {
  login: (username, password) =>
    axiosInstance.post("/auth", { username, password }),

  logout: (refreshToken) => axiosInstance.post("/user/logout", { refreshToken }),

  cofirmRegister: (token) =>
    axiosInstance.get(`/user/confirmation?token=${token}`),

  start: (extractorDto) => axiosInstance.post("/extractor/start", extractorDto),

  rename: (extractorDto) =>
    axiosInstance.post("/extractor/rename", extractorDto),

  save: () => axiosInstance.post("/extractor/"),

  executeAction: (actionDTO) =>
    axiosInstance.post("/extractor/action", actionDTO),

  getLastImage: () =>
    axiosInstance.get("/extractor/getImageNavigator", {
      hideLoading: true,
    }),

  getExtractorByToken: (token) =>
    axiosInstance.get(`/extractor/token/${token}`, {
      hideLoading: true,
    }),

  getElementPoint: (actionDTO) =>
    axiosInstance.post("/extractor/evaluate/point", actionDTO),

  reload: () => axiosInstance.get("/extractor/normalized/reload"),

  getCurrentNormalized: () =>
    axiosInstance.get("/extractor/normalized/current"),

  finish: () => axiosInstance.get("/extractor/end"),

  changeAction: (actionDTO) => axiosInstance.put("/extractor/edit", actionDTO),

  changeSequence: (currentSequence, newSequence) =>
    axiosInstance.put(
      `/extractor/sequence?currentSequence=${currentSequence}&newSequence=${newSequence}`,
      { currentSequence, newSequence }
    ),

  getNormalizedData: (action) =>
    axiosInstance.post("/extractor/normalized", action),

  previewNumberMask: (numberMaskDto) =>
    axiosInstance.post("/extractor/number/mask", numberMaskDto),

  previewDateTimeMask: (datetimeMaskDto) =>
    axiosInstance.post("/extractor/dateTime/mask", datetimeMaskDto),

  getAllTimeZones: () => axiosInstance.get("/extractor/dateTime/timezones"),

  getElementScreenshot: (sequence) =>
    axiosInstance.get(`/extractor/screenshot?sequence=${sequence}`),

  refreshToken: (refreshToken) => axiosInstance.put("/auth", { refreshToken }),

  createUser: (userDTO) => fetchInstance.request("user", "POST", userDTO),

  updateUser: (userDTO) => axiosInstance.put("/user/password", userDTO),

  forgotPasswordUser: (userDTO) =>
    fetchInstance.request("user/forgot", "PUT", userDTO),

  getExtractors: (extractorType) => 
    axiosInstance.get(`/extractor?extractorType=${extractorType}`, { hideLoading: true }),

  getExtractor: (id) => axiosInstance.get(`/extractor/edit/${id}`),

  deleteExtractor: (id) => axiosInstance.delete(`/extractor/${id}`),

  deleteAction: (sequence) =>
    axiosInstance.delete(`/extractor/action?sequence=${sequence}`),

  resequenceActions: () => axiosInstance.get("/extractor/resequence"),

  getNormalizedToEdit: (sequence) =>
    axiosInstance.get(`/extractor/edit/normalized?sequence=${sequence}`),

  getAllElementScreenshots: () =>
    axiosInstance.get("/extractor/screenshot/all"),

  executeExtractor: (extractorId) =>
    axiosInstance.get(`/extractor/execute/${extractorId}`),

  clearErrorMessage: (id) =>
    axiosInstance.put(`/extractor/clearShowErrorMessage/${id}`, {
      hideLoading: true,
    }),

  checkPendingAsk: (extractorType) =>
    axiosInstance.get(`/extractor/getPendingAskActionToFill?extractorType=${extractorType}`, {
      hideLoading: true,
    }),

  updatePendingAskAction: (action) =>
    axiosInstance.post("/extractor/updatePendingAskAction", action),

  getDataSetLabelColumn: () => axiosInstance.get("/dataset/datasetlabelcolumn"),

  createDataset: (dataset) =>
    axiosInstance.post("/dataset/createdataset", dataset),

  dataSetField: (setFields) =>
    axiosInstance.post("/dataset/datasetfield", setFields),

  datasetfieldlist: () => axiosInstance.get("/dataset/datasetfieldlist"),

  labeltablecolumnlist: (LabellistData) =>
    axiosInstance.post("/dataset/labeltablecolumnlist", LabellistData),

  dataSetSave: (step4Data, datasetId) =>
    axiosInstance.post(`/dataset/save?datasetId=${datasetId}`, step4Data),

  cancel: () => axiosInstance.get("/dataset/canceldataset"),

  datasetDashboard: () => axiosInstance.get("/dataset/datasetdashboard"),

  schedulerDashboard: () => axiosInstance.get("/extractor/scheduler/"),

  getServerTimezone: () => axiosInstance.get("/extractor/scheduler/timezone"),

  SchedulerSave: (schedulerDTO) => axiosInstance.put(`/extractor/scheduler/`, schedulerDTO),

  schedulerEdit: (schedulerId) =>
      axiosInstance.get(`/extractor/scheduler/${schedulerId}`),

  schedulerRename: (schedulerDto) =>
      axiosInstance.post("/extractor/scheduler/rename", schedulerDto),

  verifyDatasetHasData: (id) =>
    axiosInstance.get(`/dataset/verifyDatasetHasData?datasetId=${id}`),

  datasetDelete: (id) =>
    axiosInstance.get(`/dataset/deletedataset?datasetId=${id}`),

  datasetRename: (datasetDTO) =>
    axiosInstance.post("/dataset/rename", datasetDTO),

  schedulerDelete: (id) =>
      axiosInstance.delete(`/extractor/scheduler/${id}`),

  createQuery: (QueryData) =>
    axiosInstance.post("/query/createquery", QueryData),

  getDataset: () => axiosInstance.get("/query/datasetquery"),

  datasetFieldQuery: (QueryObject) =>
    axiosInstance.post("/query/datasetfieldquery", QueryObject),

  datasetFieldQueryForFilter: (QueryObject) =>
    axiosInstance.post("/query/datasetfieldqueryForFilter", QueryObject),

  datasetoperator: () => axiosInstance.get("/query/datasetoperator"),

  fieldselected: (Querystep3data) =>
    axiosInstance.post("/query/fieldselected", Querystep3data),

  fieldoperator: (Querystpep4data) =>
    axiosInstance.post("/query/fieldoperatorinputvalue", Querystpep4data),

  Queryresult: () => axiosInstance.get("/query/showqueryresult"),

  filterinputvalue: (filterobject) =>
    axiosInstance.post("/query/filterinputvalue", filterobject),

  cancelQuery: () => axiosInstance.put("/query/cancelquery"),

  showExtractorData: () => axiosInstance.get("/query/showextractoractiondata"),

  QueryDataset: (Dataset) => axiosInstance.post("/query/dataset", Dataset),

  QuerySave: (id) => axiosInstance.get(`/query/save?queryId=${id}`),

  QueryDashboard: () => axiosInstance.get("/query/querydashboard"),

  QueryEdit: (id) => axiosInstance.get(`/query/editquery?queryId=${id}`),

  QueryRename: (queryObject) =>
    axiosInstance.post("/query/rename", queryObject),

  QueryDelete: (Qid) => axiosInstance.delete(`/query/${Qid}`),

  QueryRemove: (QueryId, FieldId) =>
    axiosInstance.delete(
      `/query/removefield?queryFieldId=${QueryId}&queryFilterId=${FieldId}`
    ),
  QueryGetLinkExport: () =>
    axiosInstance.get("/query/export/excel", {
      responseType: "arraybuffer",
    }),

  getFile: (token) =>
    axiosInstance.get("/query/file/" + token),

  datasetEdit: (datasetId) =>
    axiosInstance.get(`/dataset/edit?datasetId=${datasetId}`),

  // eslint-disable-next-line max-len
  RemovedatasetField: (datasetId, datasetFieldId, datasetName) =>
    axiosInstance.delete(
      `/dataset/removedatasetfield/?datasetId=${datasetId}&datasetFieldId=${datasetFieldId}&datasetFieldName=${datasetName}`
    ),

  saveSetting: (QueryObject, Key) =>
    axiosInstance.put(`/user/settings/${Key}`, QueryObject),

  getSetting: (Key) => axiosInstance.get(`/user/settings/${Key}`),

  isEmailValid: (email) => axiosInstance.get(`/user/isEmailValid/${email}`),

  getMessagesByToken: (token) =>
      axiosInstance.get(`/message/token/${token}`, {
        hideLoading: true,
      }),

  getMessagesById: (id) =>
        axiosInstance.get(`/message/id/${id}`, {
          hideLoading: true,
        }),

  getMessagesByReplyOf: (id) =>
      axiosInstance.get(`/message/reply-of/${id}`, {
        hideLoading: true,
      }),


  getSentMessages: () =>
      axiosInstance.get(`/message/`, {
        hideLoading: true,
      }),

  getReceivedMessages: () =>
        axiosInstance.get(`/message/received`, {
          hideLoading: true,
        }),

  deleteMessagesById: (id) =>
          axiosInstance.delete(`/message/${id}`, {
            hideLoading: true,
          }),

  sendMessage: (message) =>
        axiosInstance.post('/message/add', message),

  getUserDetails: () => axiosInstance.get('/user/'),

};

export default HttpService;
