import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import {
    Paper,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Box,
    Typography
} from '@material-ui/core';
import ReplyIcon from '@material-ui/icons/Reply';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from "notistack";
import MessageService from "../../services/MessageService";
import LocalStorageService from "../../services/LocalStorageService";
import GenericModal from "../shared/GenericModal";

const formatDateTime = (value) => new Intl.DateTimeFormat('en-US',
                               {
                                   year: 'numeric',
                                   month: '2-digit',
                                   day: '2-digit',
                                   hour: '2-digit',
                                   minute: '2-digit',
                                   second: '2-digit'
                               }).format(value);

const columns = [
  {
    id: 'datetime',
    label: 'Date',
    minWidth: 170,
    align: 'right',
    format: (value) => formatDateTime(value)
  },
  { id: 'from', label: 'From', minWidth: 170 },
  { id: 'to', label: 'To', minWidth: 100 },
  { id: 'subject', label: 'Subject', minWidth: 170 },
  {
    id: 'action',
    label: 'Action',
    align: 'center'
  }
];

const useStyles = makeStyles({
  root: {
    width: '98%',
    margin: '20px'
  },
  container: {
    maxHeight: 440,
  },
  body: {
    margin: '20px',
    width: '100%',
    padding: '0 40px 20px 0'
  },
  button: {
    margin: '5px 0 0 5px'
  },
  label: {
    marginLeft: '20px'
  },
  row: {
    cursor: 'pointer'
  }
});

function Messaging() {
  const classes = useStyles();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const [replyRows, setReplyRows] = React.useState([]);
  const [body, setBody] = React.useState('');
  const [message, setMessage] = React.useState({});
  const [isToken, setIsToken] = useState(false);
  const intl = useIntl();

  const handleReply = (all, row) => {
    const sendTo = all ?
            row.to.indexOf(row.from) === -1 ? row.from + ";" + row.to : row.to
        : row.from;
    history.push({
         pathname: "/messaging-new",
         params: {
           replyOf: row.id,
           from: row.from,
           subject: row.subject,
           to: sendTo
         },
       });
  };

  const handleReturn = () => {
    history.push("/dashboard");
  };

  const makeHeader = (row) => {
    return `From: ${row.from}\nSent: ${formatDateTime(row.datetime)}\nTo: ${row.to} \nSubject: ${row.subject}\n\n`;
  }

  const addMessageToBody = (row) => {
        const divider = "-".repeat(400);
        const header = makeHeader(row);
        const newBody = `${header}${row.body}\n${divider}\n\n${body}`;
        return newBody;
  };

  const getRepliesForMessage = (initialMessageBody, id) => {
    MessageService.getMessagesByReplyOf(id).then(
      (response) => {
        const { data } = response;
        let newBody = initialMessageBody;
        if (data.length > 0)
            data.reverse().forEach((row) => { newBody = `${addMessageToBody(row)}${newBody}` });
        setBody(newBody);
      },
      (error) => {
        enqueueSnackbar(
          intl.formatMessage(
            { id: "error.unknown" },
            { errorMessage: error?.message }
          ),
          { variant: "error" }
        );
      }
    );
  }

  const getMessageById = (id) => {
    MessageService.getMessagesById(id).then(
      (response) => {
        const { data } = response;
        const row = {
            id: data.id,
            from: data.from,
            to: data.to,
            subject: data.subject,
            datetime: data.datetime,
            body: data.body
          };
        setMessage(row);
        getRepliesForMessage(addMessageToBody(row), data.id);
      },
      (error) => {
        enqueueSnackbar(
          intl.formatMessage(
            { id: "error.unknown" },
            { errorMessage: error?.message }
          ),
          { variant: "error" }
        );
      }
    );
  }

  const getMessageByToken = (token) => {
      MessageService.getMessagesByToken(token).then(
        (response) => {
          const { data } = response;
          getMessageById(data.replyOf !== null ? data.replyOf : data.id);
        },
        (error) => {
          enqueueSnackbar(
            intl.formatMessage(
              { id: "error.unknown" },
              { errorMessage: error?.message }
            ),
            { variant: "error" }
          );
        }
      );
    }

  const getMessages = useCallback(() => {

      const accessToken = LocalStorageService.getAccessToken();
      if (accessToken === null) setIsToken(true);
      if (history.location.pathname.indexOf("/message/id") === 0) {
        const id = history.location.pathname.replace("/message/id/", "");
        getMessageById(id);
      } else {
        const token = history.location.pathname.replace("/message/token/", "");
        getMessageByToken(token);
      }
    }, []);

  useEffect(async () => {
    getMessages();
  }, [getMessages]);

  return (
  <>
    <Box width="100%" marginTop="20px">
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      mr={2}
    >
      {!isToken ? (
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => handleReturn()}
          >
            <FormattedMessage id="messaging.return" />
          </Button>) : (<></>)}
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<ReplyAllIcon />}
            onClick={() => handleReply(true, message)}
          >
            <FormattedMessage id="messaging.replyAll" />
          </Button>
    </Box>

    <Paper className={classes.root}>
        <TextField
          className={classes.body}
          id="body"
          multiline
          value={body}
        />
    </Paper>
  </Box>
  </>
  );
}

export default Messaging;
