/* eslint-disable camelcase */
import { Fade, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import AppToolbar from "./app-toolbar/AppToolbar";
import Dashboard from "./dashboard/Dashboard";
import Extractor from "./extractor/Extractor";
import DataSetExtractor from "./dataset/DatasetExtractor";
import SchedulerExtractor from "./scheduler/SchedulerExtractor";
import Scheduler from "./scheduler/Scheduler";
import Logout from "./Logout";
import Settings from "./settings/Settings";
import Dataset from "./dataset/Dataset";
import Query from "./query/Query";
import QueryExtractor from "./query/QueryExtractor";
import AuthenticationService from "../services/AuthenticationService";
import LocalStorageService from "../services/LocalStorageService";
import Navigator from "./navigator/Navigator";
import NavigatorViewer from "./navigator/NavigatorViewer";
import FileDownloader from "./query/FileDownloader";
import Help from "./help/Help";

const useStyles = makeStyles(() => ({
  grid: {
    margin: 0,
    width: "100%",
  },
}));

const setAuthenticationToken = (token, history, isMessaging) => {
    LocalStorageService.setAccessToken(token);
    LocalStorageService.setFEngine(true);
    LocalStorageService.setMessaging(isMessaging);
    sessionStorage.setItem("Auth", "true");
    LocalStorageService.setRememberMeUser('');
    history.push("/dashboard");
}

function Home() {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname.indexOf("/screen-share-fengine/") === 0) {
      const token = history.location.pathname.replace("/screen-share-fengine/", "");
      setAuthenticationToken(token, history, false);
    } else if (history.location.pathname.indexOf("/messaging-fengine/") === 0) {
      const token = history.location.pathname.replace("/messaging-fengine/", "");
      setAuthenticationToken(token, history, true);
    } else if (!AuthenticationService.isAccessTokenValid()) {
      LocalStorageService.clear();
      if (history.location.pathname.indexOf("/screen-share/") === 0) {
        const token = history.location.pathname.replace("/screen-share/", "");
        LocalStorageService.setNavigatorToken(token);
      } else if (history.location.pathname.indexOf("/messaging/") === 0) {
        const token = history.location.pathname.replace("/messaging/", "");
        LocalStorageService.setNavigatorToken(token);
      }
      history.push("/login");
    }
    if (history.location.pathname === "/") history.push("/dashboard");
  }, [history]);

  return (
    <>
      {!LocalStorageService.getFEngine() ? (<AppToolbar />) : (<></>)}
      <Fade in timeout={500}>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          spacing={3}
          className={classes.grid}
        >
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/extractor" exact component={Extractor} />
          <Route path="/insertor" exact component={Extractor} />
          <Route path="/screen-share" exact component={Navigator} />
          <Route path="/screen-share/:token" exact component={NavigatorViewer} />
          <Route path="/file/:token" exact component={FileDownloader} />
          <Route path="/settings" exact component={Settings} />
          <Route path="/help" exact component={Help} />
          <Route path="/dataset-extractor" exact component={DataSetExtractor} />
          <Route path="/scheduler-extractor" exact component={SchedulerExtractor} />
          <Route path="/Scheduler" exact component={Scheduler} />
          <Route path="/dataset" exact component={Dataset} />
          <Route path="/Query" exact component={Query} />
          <Route path="/query-extractor" exact component={QueryExtractor} />
          <Route path="/logout" exact component={Logout} />
        </Grid>
      </Fade>
    </>
  );
}

export default Home;
