import { Box, Grid, makeStyles, Paper } from "@material-ui/core";
import React, { useContext, useState } from "react";
import QueryList from "./QueryList";
import MessagingList from "./MessagingList";
import DatasetList from "./DatasetList";
import ExtractorList from "./ExtractorList";
import ResponsiveDialog from "../shared/ResponsiveDialog";
import SchedulerList from "./SchedulerList";
import AppContext from "../../context/AppContext";
import ExtractorType from "../../models/enums/ExtractorType";
import LocalStorageService from "../../services/LocalStorageService";
import ChangePassword from "../change-password/ChangePassword";

const useStyles = makeStyles(() => ({
  box: {
    maxHeight: "45vh",
    overflow: "auto",
  },
}));

function Dashboard() {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [state] = useContext(AppContext);
  const fengine = LocalStorageService.getFEngine() === 'true';
  const messaging = LocalStorageService.getMessaging() === 'true';
  const tempPasswordSet = LocalStorageService.getTempPasswordSet() === 'true';

  return (
    <>
      {!tempPasswordSet && !fengine && state.showDataExtractor &&
        <Grid item sm={4}>
          <Box component={Paper} className={classes.box}>
            <ExtractorList
              setMessage={setErrorMessage}
              setOpen={setShowErrorMessage}
              extractorType={ExtractorType.EXTRACTOR}
            />
          </Box>
        </Grid>}

        {!tempPasswordSet && !fengine && state.showDataInserter &&
          <Grid item sm={4}>
            <Box component={Paper} className={classes.box}>
              <ExtractorList
                setMessage={setErrorMessage}
                setOpen={setShowErrorMessage}
                extractorType={ExtractorType.INSERTOR}
              />
            </Box>
          </Grid>}

          {!tempPasswordSet && !fengine && state.showDatasetAndQueries &&
            <Grid item sm={4}>
              <Box component={Paper} className={classes.box}>
                <DatasetList
                  setMessage={setErrorMessage}
                  setOpen={setShowErrorMessage}
                />
              </Box>
            </Grid>}

          {!tempPasswordSet && !fengine && state.showDatasetAndQueries &&
            <Grid item sm={4}>
              <Box component={Paper} className={classes.box}>
                <QueryList />
              </Box>
            </Grid>}

          {!tempPasswordSet && !fengine && state.showScheduler &&
            <Grid item sm={4}>
              <Box component={Paper} className={classes.box}>
                  <SchedulerList
                    setMessage={setErrorMessage}
                    setOpen={setShowErrorMessage}
                  />
              </Box>
            </Grid>}

          {!tempPasswordSet && (!messaging || !fengine) && state.showNavigator &&
            <Grid item sm={4}>
              <Box component={Paper} className={classes.box}>
                <ExtractorList
                  setMessage={setErrorMessage}
                  setOpen={setShowErrorMessage}
                  extractorType={ExtractorType.NAVIGATOR}
                />
              </Box>
            </Grid>}

          {!tempPasswordSet && (messaging || !fengine) && state.showMessaging &&
          <Grid item sm={4}>
            <Box component={Paper} className={classes.box}>
              <MessagingList />
            </Box>
          </Grid>}

          <ResponsiveDialog
            message={errorMessage}
            isOpen={showErrorMessage}
            setOpen={setShowErrorMessage}
          />

          {tempPasswordSet && <>
            <ChangePassword />
          </>}

          {/* <Grid item sm={4}>
            <Box component={Paper} className={classes.box}>
              <AlertList />
            </Box>
          </Grid>

          <Grid item sm={4}>
            <Box component={Paper} className={classes.box}>
              <NotificationList />
            </Box>
          </Grid> */}
    </>
  );
}

export default Dashboard;
